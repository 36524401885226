import React from "react";
import Layout from "../../areas/main/components/Layout";
import NavOne from "../../areas/main/components/NavOne";
import Texts from "../../utils/lang-utils";
import Intro from "./components/Intro";
import Features from "./components/Features";
import KitchenAppSection from "./components/KitchenAppSection";
import Footer from "../../areas/main/components/Footer"
import WaiterAppSection  from "./components/WaiterAppSection";
import OtherServices from "./components/OtherServices";


export default () => { 

return <Layout pageTitle={Texts.siteTitle} language="en">
    <NavOne catchScroll={false} />
    <Intro />
    <Features />
    <WaiterAppSection />
    <KitchenAppSection />
    <OtherServices />
    <Footer />
</Layout>};