import React from "react";
import waiterAppVideo from "../../../areas/main/assets/videos/waiter-app.mp4";
import Texts from "../../../utils/lang-utils";
import "./WaiterAppSection.scss";
import urls from "../../../areas/main/assets/urls";
const CallToAction = () => {
  return (
    <section className="cta-one waiter-app" id="waiter-app">
      <div className="container">
          
        <div className="row justify-content-lg-end">
          <div className="col-md-6 text-center">
          <div className="cta-one__moc mobile-hide">
            <video muted loop autoPlay playsInline>
                  <source src={waiterAppVideo} className="cta-two__moc" type="video/mp4"></source>
              </video>
          </div>
          </div>
          <div className="col-md-6">
            <div className="cta-one__content">
              
              <div className="block-title text-left">
                <h2 className="block-title__title" dangerouslySetInnerHTML={{__html: Texts.Apps.WaiterAppTitle }} />
              </div>
              <div className="mobile-show text-center mb-5" style={{ display: 'none' }}>

              <div className="cta-one__moc text-center ">
                  <video muted loop autoPlay playsInline>
                        <source src={waiterAppVideo} className="cta-two__moc" type="video/mp4"></source>
                    </video>
              </div>

              </div>
              
              <h3>{Texts.Apps.WaiterAppSubtitle}</h3>              
              <ul className="list-unstyled cta-one__text">
                <li>
                  <i className="fa fa-check"></i><p dangerouslySetInnerHTML={{__html: Texts.Apps.WaiterAppBullet1 }} />
                </li>
                <li>
                  <i className="fa fa-check"></i><p dangerouslySetInnerHTML={{__html: Texts.Apps.WaiterAppBullet2 }} />
                </li>
                <li>
                  <i className="fa fa-check"></i><p dangerouslySetInnerHTML={{__html: Texts.Apps.WaiterAppBullet3 }} />
                </li>
                <li>
                  <i className="fa fa-check"></i><p dangerouslySetInnerHTML={{__html: Texts.Apps.WaiterAppBullet4 }} />
                </li>
                <li>
                  <i className="fa fa-check"></i><p dangerouslySetInnerHTML={{__html: Texts.Apps.WaiterAppBullet5 }} />
                </li>
              </ul>
              <a href={urls.onboarding} className="thm-btn">
                <span>{Texts.GetStarted}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default CallToAction;
