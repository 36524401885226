import React from "react";
import Texts from "../../../utils/lang-utils";
import background from '../../../areas/main/assets/images/background/banner-2-bg.jpg';
import "./Features.scss";
import restaurantAppsImage from '../../../areas/main/assets/images/resources/restaurant-apps.jpg';
import urls from "../../../areas/main/assets/urls";

const OtherServices = () => {
    return (
        <section className="service-one d-flex align-items-center features" style={{ backgroundImage: `url('${background}')` }}>
            <div className="container text-center ">
                <div className="block-title text-center">
                    <h2 className="block-title__title light" dangerouslySetInnerHTML={{ __html: Texts.Apps.FeaturesTitle }} />
                </div>
                <div className="row">

                    <div className=" col-md-6 text-left">
                        <h3>{Texts.Apps.FeaturesSubtitle}</h3>
                        <p><i className="fa fa-check"></i> {Texts.Apps.FeaturesItem1}</p>
                        <p><i className="fa fa-check"></i> {Texts.Apps.FeaturesItem2}</p>
                        <p><i className="fa fa-check"></i> {Texts.Apps.FeaturesItem3}</p>
                        <p><i className="fa fa-check"></i> {Texts.Apps.FeaturesItem4}</p>
                        <p><i className="fa fa-check"></i> {Texts.Apps.FeaturesItem5}</p>
                        <p><i className="fa fa-check"></i> {Texts.Apps.FeaturesItem6}</p>
                        <div>
                    <div className="text-left mt-5">
                        <a href={urls.onboarding} className="thm-btn">
                            <span>{Texts.GetStarted}</span>
                        </a>
                    </div>

                </div>
                    </div>

                    <div className=" col-md-6 text-left mt-5 mobile-hide">
                    <img src={restaurantAppsImage} alt="alter text" style={{
                        maxWidth: '100%',
                        borderRadius: 20,
            
                        boxShadow: '0px 20px 60px 0px rgb(244 151 190 / 80%)'
                    }} />
                    </div>

                </div>
                
            </div>
        </section>
    );
};
export default OtherServices;
